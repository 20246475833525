import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Helmet from 'react-helmet';
//import { GatsbySeo } from 'gatsby-plugin-next-seo';

export default (props) => {
    const { colour } = props;
    return (
	<StaticQuery
		query={graphql`
			query helmetQuery {
				site {
					siteMetadata {
						title
						author
						imageUrl
						description
						keywords
					}
				}
			}
		`}
		render={data => (
			<Helmet>
				<meta
					name="viewport"
					content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0"
				/>
				<meta name="description" content={data.site.siteMetadata.description} />
				<meta name="keywords" content={data.site.siteMetadata.keywords} />
				<title>{data.site.siteMetadata.title}</title>
				<html lang="en" />
				{/* Google / Search Engine Meta Tags */}
				<meta itemprop="name" content={data.site.siteMetadata.author} />
				<meta
					itemprop="description"
					content={data.site.siteMetadata.description}
				/>
				{/*<meta itemprop="image" content={data.site.siteMetadata.imageUrl} />*/}
                <meta name="msapplication-TileColor" content={colour} />
                <meta name="theme-color" content={colour} />
			</Helmet>
		)}
	/>
    )
}
